import "./styles.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useGlobalState } from "../index";
import Button from "antd/lib/button";
import VideoContainer from "../VideoContainer";
import { changeNetwork } from "../utils/change-network";
import { connectWallet, isMetaMaskInstalled } from "../utils/connect-wallet";

export function Login() {
  const [state, dispatch] = useGlobalState();
  const navigate = useNavigate();

  const [changeNetworkLoaderState, setChangeNetworkLoaderState] =
    useState<boolean>(false);

  const checkLogin = async (): Promise<void> => {
    if (state.connected) navigate("/");
  };

  const changeToDefaultChain = async () => {
    try {
      setChangeNetworkLoaderState(true);
      await changeNetwork();
      setChangeNetworkLoaderState(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkLogin();
  }, [state.connected]);

  return (
    <div className="login-container">
      <div className="login-content">
        <div className="loader-video-container">
          <VideoContainer size={300} boxType={3} autoplay={true} />
        </div>
        <h1>DGFamily Glass Box Reveal</h1>
        <p>
          To reveal your DGFamily Glass Box, you will need to be logged in to
          MetaMask and connected to the Ethereum mainnet.
        </p>
        <div className="login-actions">
          {isMetaMaskInstalled() &&
          state.chainId !==
            parseInt(process.env.REACT_APP_NETWORK_ID as string) ? (
            <Button
              size="large"
              type="primary"
              onClick={() => changeToDefaultChain()}
              loading={changeNetworkLoaderState}
            >
              {changeNetworkLoaderState === true
                ? "Changing Network ..."
                : "Change Network"}
            </Button>
          ) : (
            <Button
              type="primary"
              className="connect"
              onClick={() => connectWallet(dispatch)}
              disabled={
                isMetaMaskInstalled() &&
                state.chainId !==
                  parseInt(process.env.REACT_APP_NETWORK_ID as string)
              }
            >
              Connect Wallet
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Login;
