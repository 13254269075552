import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

// WEB3
import { getProvider } from "../provider";

// ANTD
import { Button } from "antd";
import CloseCircleFilled from "@ant-design/icons/lib/icons/CloseCircleFilled";

// STATE
import { useGlobalState, User } from "../index";

// MODUlES
import Stepper from "../shared/Stepper";
import TransactionHash from "../shared/TransactionHash";
import VideoContainer from "../VideoContainer";

// STYLES
import "./styles.scss";

import { connectWallet, isMetaMaskInstalled } from "../utils/connect-wallet";
import { changeNetwork } from "../utils/change-network";

// ABIS
const abi = require("../assets/abis/DGFamilyGlassBox.json");

export const APPROVAL_ERROR_MSG = [
  "There was an error approving your contract!",
  "Please try again or contact support.",
];

function Approval() {
  const [state, dispatch] = useGlobalState();
  const [loaderState, setLoaderState] = useState<boolean>(false);
  const [approvalLoaderState, setApprovalLoaderState] =
    useState<boolean>(false);
  const [changeNetworkLoaderState, setChangeNetworkLoaderState] =
    useState<boolean>(false);
  const [txHashState, setTxHashState] = useState<string>("");
  const [approvalErrorState, setApprovalErrorState] = useState<string[]>();

  const navigate = useNavigate();

  const approve = async (): Promise<void> => {
    setApprovalLoaderState(true);

    const provider = await getProvider();
    const contract = new provider.eth.Contract(
      abi.abi,
      process.env.REACT_APP_CONTRACT_GLASS_BOX
    );

    try {
      const estimatedGas = await contract.methods
        .setApprovalForAll(process.env.REACT_APP_CONTRACT_REVEAL, true)
        .estimateGas({
          from: state.user,
        });

      await contract.methods
        .setApprovalForAll(process.env.REACT_APP_CONTRACT_REVEAL, true)
        .send({ from: state.user, gas: estimatedGas })
        .on("transactionHash", (transactionHash: string) => {
          setTxHashState(transactionHash);
        });

      dispatch({ isApproved: true });
      setApprovalLoaderState(false);
      navigate("/glass-boxes/select");
    } catch (error: any) {
      // HANDLE ERROR
      console.log(error);
      setApprovalErrorState(APPROVAL_ERROR_MSG);
    }
  };

  const checkApproval = async (user: User): Promise<void> => {
    const provider = await getProvider();
    const contract = new provider.eth.Contract(
      abi.abi,
      process.env.REACT_APP_CONTRACT_GLASS_BOX
    );

    try {
      const isApprovedForAll = await contract.methods
        .isApprovedForAll(user, process.env.REACT_APP_CONTRACT_REVEAL)
        .call({ from: user });
      if (isApprovedForAll) {
        dispatch({ isApproved: isApprovedForAll });
        setLoaderState(false);
        navigate("/glass-boxes/select");
      } else {
        setLoaderState(false);
      }
    } catch (error: any) {
      // HANDLE ERROR
      console.log(error);
      setLoaderState(false);
    }
  };

  const tryAgain = (): void => {
    navigate("/glass-boxes/select");
  };

  const contactSupport = (): void => {
    window.open('mailto:"help@unxd.com"');
  };

  const changeToDefaultChain = async () => {
    try {
      setChangeNetworkLoaderState(true);
      await changeNetwork();
      setChangeNetworkLoaderState(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    state.user && checkApproval(state.user);
  }, [state.user]);

  if (approvalErrorState) {
    return (
      <div className="approval-container">
        <Stepper />
        <div className="approval-content">
          <h1>Reveal</h1>
          <div className="error-container">
            <CloseCircleFilled className="error-icon" /> Error: There was an
            error during the revealing process
          </div>
          <TransactionHash txHash={txHashState} />
          <div className="error-actions-container">
            <Button
              type="primary"
              size="large"
              onClick={() => tryAgain()}
            ></Button>
            <Button
              type="primary"
              size="large"
              onClick={() => contactSupport()}
            >
              Contact Support
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="approval-container">
      <Stepper />
      <div className="approval-content">
        <h1>Approve</h1>
        <div className="loader-video-container">
          <VideoContainer size={270} autoplay={true} boxType={3} />
        </div>
        <p>
          To reveal your DGFamily Glass Box, you will first need to provide
          access to your Box. This requires you to authorize an approval to the
          DGFamily Glass Box reveal contract. You will need to do this only
          once, regardless of how many Boxes you choose to reveal.
        </p>
        <p className="not-refresh">
          Note: Please don’t refresh this page while the transaction is in
          progress.
        </p>
        {approvalLoaderState && txHashState ? (
          <TransactionHash txHash={txHashState} />
        ) : null}
        <div className="approval-buttons">
          {state.connected ? (
            <>
              {state.chainId ===
              parseInt(process.env.REACT_APP_NETWORK_ID as string) ? (
                <>
                  {loaderState && !approvalLoaderState ? (
                    <Button
                      size="large"
                      type="primary"
                      onClick={() => approve()}
                      disabled={
                        isMetaMaskInstalled() &&
                        state.chainId !==
                          parseInt(process.env.REACT_APP_NETWORK_ID as string)
                      }
                      loading={loaderState}
                    >
                      {loaderState === true
                        ? "Checking Approval ..."
                        : "Approve"}
                    </Button>
                  ) : null}
                  {!loaderState ? (
                    <Button
                      size="large"
                      type="primary"
                      onClick={() => approve()}
                      disabled={
                        isMetaMaskInstalled() &&
                        state.chainId !==
                          parseInt(process.env.REACT_APP_NETWORK_ID as string)
                      }
                      loading={approvalLoaderState}
                    >
                      {approvalLoaderState === true
                        ? "Approving ..."
                        : "Approve"}
                    </Button>
                  ) : null}
                </>
              ) : (
                <Button
                  size="large"
                  type="primary"
                  onClick={() => changeToDefaultChain()}
                  loading={changeNetworkLoaderState}
                >
                  {changeNetworkLoaderState === true
                    ? "Changing Network ..."
                    : "Change Network"}
                </Button>
              )}
            </>
          ) : (
            <>
              {isMetaMaskInstalled() &&
              state.chainId !==
                parseInt(process.env.REACT_APP_NETWORK_ID as string) ? (
                <Button
                  size="large"
                  type="primary"
                  onClick={() => changeToDefaultChain()}
                  loading={changeNetworkLoaderState}
                >
                  {changeNetworkLoaderState === true
                    ? "Changing Network ..."
                    : "Change Network"}
                </Button>
              ) : (
                <Button
                  type="primary"
                  className="connect"
                  onClick={() => connectWallet(dispatch)}
                  disabled={
                    isMetaMaskInstalled() &&
                    state.chainId !==
                      parseInt(process.env.REACT_APP_NETWORK_ID as string)
                  }
                >
                  Connect Wallet
                </Button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Approval;
