import React, { useEffect } from "react";
import { useNavigate } from "react-router";

// STATE
import { DgBox, useGlobalState } from "../index";

// ANTD
import { Button } from "antd";

// MODUlES
import RevealedItem from "../shared/RevealedItem";
import Stepper from "../shared/Stepper";

// STYLES
import "./styles.scss";

function RevealedItems() {
  const [state, dispatch] = useGlobalState();

  const navigate = useNavigate();

  const navigateTo = (route: string): void => {
    navigate(route);
  };

  const revealMore = (): void => {
    window.location.href = "/glass-boxes/select";
  };

  const checkDgBoxes = (): void => {
    if (state.dgBoxes.length === 0) revealMore();
  };

  useEffect(() => {
    checkDgBoxes();
  }, []);

  return (
    <div className="revealed-items-container">
      <Stepper />
      <div className="revealed-items-content">
        <div className="revealed-items-header-container">
          <div className="revealed-items-header-content">
            <h1>Congratulations!</h1>
            <p>
              You have burned your Glass{" "}
              {state.dgBoxes.length > 1 ? "Boxes" : "Box"} and claimed the
              following DGFamily {state.dgBoxes.length > 1 ? "Boxes" : "Box"} in
              your wallet. View your{" "}
              {state.dgBoxes.length > 1 ? "Boxes" : "Box"} in your UNXD account
              or profiles on other platforms.
            </p>
          </div>
        </div>
        <div className="revealed-items-box-container">
          <div className="revealed-items-box-content">
            {state.dgBoxes.map((dgBox: DgBox, i: number) => (
              <RevealedItem dgBox={dgBox} size={300} key={i} />
            ))}
          </div>
        </div>
      </div>
      <div className="reveal-items-actions-container">
        <div className="reveal-items-actions-content">
          <Button type="primary" size="large" onClick={() => revealMore()}>
            Reveal More
          </Button>
          <span
            className="view-revealed-boxes"
            onClick={() => navigateTo("/revealed-boxes")}
          >
            View Revealed Boxes
          </span>
        </div>
      </div>
    </div>
  );
}

export default RevealedItems;
