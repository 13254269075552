import "./styles.scss";
import { useState } from "react";
import Button from "antd/lib/button";
import { useGlobalState } from "../../index";
import { formatAddress } from "../../utils/format-address";
import { connectWallet, isMetaMaskInstalled } from "../../utils/connect-wallet";
import { changeNetwork } from "../../utils/change-network";

function Header() {
  const [state, dispatch] = useGlobalState();

  const [changeNetworkLoaderState, setChangeNetworkLoaderState] =
    useState<boolean>(false);

  const changeToDefaultChain = async () => {
    try {
      setChangeNetworkLoaderState(true);
      await changeNetwork();
      setChangeNetworkLoaderState(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="header-container">
      <div className="header-content">
        <img
          src="../../assets/img/logo_dark.png"
          width="111px"
          alt="UNXD Logo"
        />
        <ul>
          <li>
            <a href="https://unxd.com/drops">Drops</a>
          </li>
          <li>
            <a href="https://unxd.com/marketplace">Marketplace</a>
          </li>
        </ul>
        {state.connected ? (
          <Button type="primary" className="connected">
            {formatAddress(state.user)}
          </Button>
        ) : (
          <>
            {isMetaMaskInstalled() &&
            state.chainId !==
              parseInt(process.env.REACT_APP_NETWORK_ID as string) ? (
              <Button
                size="large"
                type="primary"
                onClick={() => changeToDefaultChain()}
                loading={changeNetworkLoaderState}
              >
                {changeNetworkLoaderState === true
                  ? "Changing Network ..."
                  : "Change Network"}
              </Button>
            ) : (
              <Button
                type="primary"
                className="connect"
                onClick={() => connectWallet(dispatch)}
                disabled={
                  isMetaMaskInstalled() &&
                  state.chainId !==
                    parseInt(process.env.REACT_APP_NETWORK_ID as string)
                }
              >
                Connect Wallet
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Header;
