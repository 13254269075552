export const changeNetwork = async () => {
  await window.ethereum.request({
    method: "wallet_switchEthereumChain",
    params: [
      {
        chainId: `0x${parseInt(process.env.REACT_APP_NETWORK_ID as string)}`,
      },
    ],
  });
};
