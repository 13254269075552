import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

// WEB3
import { TransactionReceipt } from "web3-eth";
import { getProvider } from "../provider";

// ANTD
import Button from "antd/lib/button";
import CheckCircleFilled from "@ant-design/icons/lib/icons/CheckCircleFilled";
import CloseCircleFilled from "@ant-design/icons/lib/icons/CloseCircleFilled";

// STATE
import { DgBox, useGlobalState } from "../index";

// MODULES
import Stepper from "../shared/Stepper";
import VideoContainer from "../VideoContainer";

// STYLES
import "./styles.scss";

// ASSETS
import LinkOut from "../assets/img/link_out.svg";

// UTILS
import { formatAddress } from "../utils/format-address";

// INTERFACES
import { EventData } from "web3-eth-contract";
import { TxInfo } from "../shared/interfaces";

// CONSTANTS
export const ERROR_MSG =
  "Error: There was an error during the revealing process";
export const TIMEOUT_ERROR_MSG =
  "Your reveal has taken too long on the Ethereum network to accurately track. However, the transaction may still complete. Watch your wallet contents for the revealed boxes after they mint.";
export const MINING_STATE = "MINING";
export const REVEALING_STATE = "REVEALING";
export const TIME_OUT = 5000;
export const BLOCK_NUM_TIMEOUT = 10000;
export const CONFIRMATION_BLOCK = 3;

// ABIS
const abi = require("../assets/abis/DGFamilyReveal.json");

let navigateFlag = false;

function Reveal() {
  const navigate = useNavigate();

  const [state, dispatch] = useGlobalState();
  const [counterState, setCounterState] = useState<string>("00:00");
  const [stepperState, setStepperState] = useState<string>(MINING_STATE);
  const [errorState, setErrorState] = useState<boolean>(false);
  const [errorMsgState, setErrorMsgState] = useState<string>(ERROR_MSG);

  const listenRevealed = async (tokenIds: string[]): Promise<void> => {
    setStepperState(REVEALING_STATE);

    const provider = await getProvider();
    const contract = new provider.eth.Contract(
      abi.abi,
      process.env.REACT_APP_CONTRACT_REVEAL
    );
    const lastBlockNumber = await provider.eth.getBlockNumber();
    const dgBoxes: DgBox[] = [];

    try {
      const eventsData = await contract.events.Revealed({
        fromBlock: lastBlockNumber,
      });

      eventsData.on("data", (event: EventData) => {
        const glassTokenId = parseInt(event.returnValues.glassBoxTokenId);

        if (!tokenIds.includes(glassTokenId as any)) {
          return;
        }

        if (dgBoxes.length < state.claimBoxes.length) {
          dgBoxes.push({
            boxType: parseInt(event.returnValues.boxType),
            dgTokenId: parseInt(event.returnValues.dgTokenId),
            frame: getBoxFrameByBoxTypeId(
              parseInt(event.returnValues.boxType)
            ) as string,
          });
        }

        if (dgBoxes.length === state.claimBoxes.length && !navigateFlag) {
          navigateFlag = true;

          dispatch({ dgBoxes });

          setStepperState("");

          localStorage.removeItem("txInfo");

          if (state.claimBoxes.length === 1)
            navigate(`/glass-boxes/${dgBoxes[0].boxType}`);
          if (state.claimBoxes.length > 1) navigate(`/glass-boxes/5`);
        }
      });
    } catch (error: any) {
      // HANDLE ERROR
      console.log("ERROR!");
      setErrorState(true);
    }
  };

  const checkBlockNum = async (
    transactionBlockNumber: number
  ): Promise<void> => {
    const provider = await getProvider();

    setTimeout(async () => {
      const lastBlockNumer = await provider.eth.getBlockNumber();
      const blockDifference = lastBlockNumer - transactionBlockNumber;

      if (blockDifference > 50) {
        setErrorState(true);
        setErrorMsgState(TIMEOUT_ERROR_MSG);
      } else {
        checkBlockNum(transactionBlockNumber);
      }
    }, BLOCK_NUM_TIMEOUT);
  };

  const getBoxTypeByBoxId = (dgFamilyBoxId: number): number => {
    if (dgFamilyBoxId <= 75) return 2;
    if (dgFamilyBoxId > 75 && dgFamilyBoxId <= 750) return 1;
    return 0;
  };

  const getBoxFrameByBoxId = (dgFamilyBoxId: number): string => {
    if (dgFamilyBoxId <= 75) return "platinum.jpg";
    if (dgFamilyBoxId > 75 && dgFamilyBoxId <= 750) return "gold.jpg";
    return "black.jpg";
  };

  const getBoxFrameByBoxTypeId = (boxTypeId: number): string | void => {
    if (boxTypeId === 0) return "black.jpg";
    if (boxTypeId === 1) return "gold.jpg";
    if (boxTypeId === 2) return "platinum.jpg";
  };

  const initCounter = (): void => {
    let seconds = parseInt(localStorage.getItem("seconds") as string) || 0;
    let minutes = parseInt(localStorage.getItem("minutes") as string) || 0;

    setInterval(() => {
      seconds++;
      if (seconds === 60) {
        seconds = 0;
        minutes++;
      }
      let time = `${formatTime(minutes)}:${formatTime(seconds)}`;

      setCounterState(time);

      localStorage.setItem("seconds", seconds.toString());
      localStorage.setItem("minutes", minutes.toString());
    }, 1000);
  };

  const checkPendingReveal = async (): Promise<void> => {
    const storedTokens = localStorage.getItem("txInfo");
    if (storedTokens) {
      const txInfo = JSON.parse(storedTokens) as TxInfo;
      dispatch({ revealTxHash: txInfo.txHash });
      checkTransactionStatus(txInfo);
    } else {
      navigate("/glass-boxes/select");
    }
  };

  const checkTransactionStatus = async (txInfo: TxInfo): Promise<void> => {
    try {
      const provider = await getProvider();
      const revealTxState = await provider.eth.getTransactionReceipt(
        txInfo.txHash
      );
      if (!revealTxState) {
        setTimeout(() => checkTransactionStatus(txInfo), TIME_OUT);
      } else if (revealTxState.status) {
        confirmationBlock(revealTxState, txInfo);
        checkBlockNum(revealTxState.blockNumber);
      } else if (!revealTxState.status) {
        setErrorState(true);
      }
    } catch (error: any) {
      setErrorState(true);
    }
  };

  const checkTransactionListenerStatus = async (
    txHash: string
  ): Promise<void> => {
    try {
      const provider = await getProvider();
      const revealTxState = await provider.eth.getTransactionReceipt(txHash);

      if (!revealTxState) {
        setTimeout(() => checkTransactionListenerStatus(txHash), TIME_OUT);
      } else if (revealTxState.status) {
        confirmationBlockListen(revealTxState);
      } else if (!revealTxState.status) {
        setErrorState(true);
      }
    } catch (error: any) {
      setErrorState(true);
    }
  };

  const confirmationBlock = async (
    transactionReceipt: TransactionReceipt,
    txInfo: TxInfo
  ): Promise<void> => {
    const provider = await getProvider();
    const blockNumber = await provider.eth.getBlockNumber();

    if (transactionReceipt.blockNumber + CONFIRMATION_BLOCK >= blockNumber) {
      checkRevealStatus(txInfo);
    } else {
      setTimeout(() => confirmationBlock(transactionReceipt, txInfo), TIME_OUT);
    }
  };

  const confirmationBlockListen = async (
    transactionReceipt: TransactionReceipt
  ): Promise<void> => {
    const provider = await getProvider();
    const blockNumber = await provider.eth.getBlockNumber();

    if (transactionReceipt.blockNumber + CONFIRMATION_BLOCK >= blockNumber) {
      listenRevealed(state.claimBoxes);
      checkBlockNum(transactionReceipt.blockNumber);
    } else {
      setTimeout(() => confirmationBlockListen(transactionReceipt), TIME_OUT);
    }
  };

  const checkRevealStatus = async (txInfo: TxInfo): Promise<void> => {
    setStepperState(REVEALING_STATE);

    const tokenRevealStateResults: boolean[] = [];
    const dgBoxes: DgBox[] = [];

    for (let i = 0; i < txInfo.tokens.length; i++) {
      const tokenRevealStateResult = (await checkTokenRevealStatus(
        parseInt(txInfo.tokens[i])
      )) as boolean;
      tokenRevealStateResults.push(tokenRevealStateResult);
    }

    const tokenRevealStateFilteredResults = tokenRevealStateResults.filter(
      (tokenRevealStateResult: boolean) => tokenRevealStateResult === true
    );
    if (tokenRevealStateFilteredResults.length === txInfo.tokens.length) {
      for (let i = 0; i < txInfo.tokens.length; i++) {
        const tokenRevealResult = (await checkRevealedResults(
          parseInt(txInfo.tokens[i])
        )) as number;
        dgBoxes.push({
          boxType: getBoxTypeByBoxId(tokenRevealResult),
          dgTokenId: tokenRevealResult,
          frame: getBoxFrameByBoxId(tokenRevealResult),
        });
      }

      dispatch({ dgBoxes });

      setStepperState("");

      localStorage.removeItem("txInfo");

      if (dgBoxes.length === 1) navigate(`/glass-boxes/${dgBoxes[0].boxType}`);
      if (dgBoxes.length > 1) navigate(`/glass-boxes/5`);
    } else {
      setTimeout(() => checkRevealStatus(txInfo), TIME_OUT);
    }
  };

  const checkRevealedResults = async (
    tokenId: number
  ): Promise<number | void> => {
    try {
      const provider = await getProvider();
      const contract = new provider.eth.Contract(
        abi.abi,
        process.env.REACT_APP_CONTRACT_REVEAL
      );
      const tokenRevealResult = await contract.methods
        .revealedResults(tokenId)
        .call({ from: state.user });
      return tokenRevealResult;
    } catch (error: any) {
      setErrorState(true);
    }
  };

  const checkTokenRevealStatus = async (
    tokenId: number
  ): Promise<boolean | void> => {
    try {
      const provider = await getProvider();
      const contract = new provider.eth.Contract(
        abi.abi,
        process.env.REACT_APP_CONTRACT_REVEAL
      );
      const tokenRevealStatus = await contract.methods
        .revealedTokens(tokenId)
        .call({ from: state.user });
      return tokenRevealStatus;
    } catch (error: any) {
      setErrorState(true);
    }
  };

  const formatTime = (seconds: number): string => {
    if (seconds < 10) return `0${seconds}`;
    return seconds.toString();
  };

  const tryAgain = (): void => {
    localStorage.removeItem("txInfo");
    navigate("/glass-boxes/select");
  };

  const contactSupport = (): void => {
    window.open('mailto:"help@unxd.com"');
  };

  useEffect(() => {
    initCounter();
    !state.claimBoxes.length && checkPendingReveal();
    state.claimBoxes.length &&
      checkTransactionListenerStatus(state.revealTxHash);
  }, []);

  if (errorState) {
    return (
      <div className="reveal-container">
        <Stepper />
        <div className="reveal-content">
          <h1>Reveal</h1>
          <div className="error-container">
            <CloseCircleFilled className="error-icon" /> {errorMsgState}
          </div>
          <div className="transaction-hash-content">
            txn:
            <a
              className="transaction-address-link"
              href={`${process.env.REACT_APP_ETHERSCAN}/tx/${state.revealTxHash}`}
              target="_blank"
              rel="noreferrer"
            >
              <span className="transaction-address">
                {state.revealTxHash ? formatAddress(state.revealTxHash) : "..."}
              </span>
              <img src={LinkOut} />
            </a>
          </div>
          <div className="error-actions-container">
            <Button type="primary" size="large" onClick={() => tryAgain()}>
              Try Again
            </Button>
            <Button
              type="primary"
              size="large"
              onClick={() => contactSupport()}
            >
              Contact Support
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="reveal-container">
      <Stepper />
      <div className="reveal-content">
        <h1>Reveal</h1>
        <div className="loader-video-container">
          <VideoContainer size={300} boxType={6} autoplay={true} />
        </div>
        <div className="messages-container">
          <p>
            Chainlink VRF is randomly selecting a DGFamily Box of type Black,
            Gold, or Platinum for each Glass Box you are revealing and minting
            them to your wallet.
          </p>
        </div>
        <div className="counter-container">
          <ul className="counter-state">
            <li
              className={`${stepperState === MINING_STATE ? "active" : ""} ${
                stepperState === REVEALING_STATE ? "done" : ""
              }`}
            >
              {stepperState === REVEALING_STATE ? (
                <>
                  <CheckCircleFilled className="check-icon" /> Transaction has
                  been mined.
                </>
              ) : (
                "Transaction is being mined ..."
              )}
            </li>
            <li className={stepperState === REVEALING_STATE ? "active" : ""}>
              {stepperState === REVEALING_STATE
                ? "Reveal in progress ..."
                : "Reveal initializing ..."}
            </li>
          </ul>
          <p className="counter-timer">{counterState}</p>
        </div>
        <div className="transaction-hash-content">
          <p>
            This process can take 2-10 minutes.
            <br />
            Please be patient.
          </p>
          txn:
          <a
            className="transaction-address-link"
            href={`${process.env.REACT_APP_ETHERSCAN}/tx/${state.revealTxHash}`}
            target="_blank"
            rel="noreferrer"
          >
            <span className="transaction-address">
              {state.revealTxHash ? formatAddress(state.revealTxHash) : "..."}
            </span>
            <img src={LinkOut} />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Reveal;
