import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { createAlchemyWeb3, Nft } from "@alch/alchemy-web3";
import axios from "axios";

// ETHERS
import { BigNumber } from "ethers/lib/ethers";

// WEB3
import { getProvider } from "../provider";

// STATE
import { useGlobalState } from "../index";

// ANTD
import { Button } from "antd";
import Modal from "antd/lib/modal/Modal";

// MODUlES
import Loader from "../shared/Loader";
import NoItems from "../shared/NoItems";
import RevealItem from "../shared/RevealItem";
import Stepper from "../shared/Stepper";

// STYLES
import "./styles.scss";

// INTERFACES
import { TxInfo } from "../shared/interfaces";

// ABIS
const abi = require("../assets/abis/DGFamilyReveal.json");
const tokenAbi = require("../assets/abis/DGFamilyGlassBox.json");

// CONSTANTS
export const SELECT_TITLE = "Loading";
export const SELECT_CONTENT = "We are loading your Glass Boxes ...";

function Select() {
  const [state, dispatch] = useGlobalState();
  const [claimLoaderState, setClaimLoaderState] = useState<boolean>(false);
  const [loaderState, setLoaderState] = useState<boolean>(true);
  const [availableTokenIdsState, setAvailableTokenIdsState] = useState<
    number[]
  >([]);
  const [isAvgModalVisible, setAvgModalVisible] = useState<boolean>(false);
  const [revealStateLoader, setRevealStateLoader] = useState<boolean>(false);
  const [avgGasModalContentStep, setAvgModalContentStep] =
    useState<string>("STEP1");
  const [gasLimit, setGasLimit] = useState<string>();

  const navigate = useNavigate();

  const checkIsApproved = async () => {
    const provider = await getProvider();
    const contract = new provider.eth.Contract(
      tokenAbi.abi,
      process.env.REACT_APP_CONTRACT_GLASS_BOX
    );

    const isApprovedForAll = await contract.methods
      .isApprovedForAll(state.user, process.env.REACT_APP_CONTRACT_REVEAL)
      .call();

    if (!isApprovedForAll) {
      navigate("/glass-boxes/approval");
    }
  };

  useEffect(() => {
    if (state.user) {
      checkIsApproved();
    }
  }, [state.user]);

  const randomization = async (): Promise<void> => {
    console.log("Requesting random number from chainlink ...");
    setClaimLoaderState(true);

    const provider = await getProvider();
    const contract = new provider.eth.Contract(
      abi.abi,
      process.env.REACT_APP_CONTRACT_REVEAL
    );

    // fetch the latest reveal fees from contract.
    const revealFeesInWei = await contract.methods.revealFees().call();

    // calculate total fees = no of boxes * reveal fees
    const value = BigNumber.from(revealFeesInWei)
      .mul(state.claimBoxes.length)
      .toHexString();

    const estimatedGas = await contract.methods
      .requestRandomNumber(state.claimBoxes)
      .estimateGas({
        from: state.user,
      });

    await contract.methods
      .requestRandomNumber(state.claimBoxes)
      .send({ from: state.user, gas: estimatedGas, value })
      .on("transactionHash", (transactionHash: string) => {
        const txInfoStorage: TxInfo = {
          txHash: transactionHash,
          tokens: state.claimBoxes,
        };

        localStorage.setItem("txInfo", JSON.stringify(txInfoStorage));
        localStorage.removeItem("seconds");
        localStorage.removeItem("minutes");

        dispatch({ revealTxHash: transactionHash });

        setClaimLoaderState(false);

        navigate("/glass-boxes/reveal");
      })
      .on("error", (error: any) => {
        // ERROR
        setClaimLoaderState(false);
        handleCancel();
      });
  };

  const navigateTo = (route: string): void => {
    navigate(route);
  };

  const getTokenIdsAlchemy = async (user: string): Promise<void> => {
    const web3 = createAlchemyWeb3(process.env.REACT_APP_ALCHEMY_API as string);

    const nfts = await web3.alchemy.getNfts({
      owner: user,
      contractAddresses: [process.env.REACT_APP_CONTRACT_GLASS_BOX as string],
    });

    const walletAddressTokenIds: number[] = nfts.ownedNfts.map((nft: Nft) =>
      parseInt(nft.id.tokenId)
    );

    setAvailableTokenIdsState(walletAddressTokenIds);
    setLoaderState(false);
  };

  const handleCancel = (): void => {
    setClaimLoaderState(false);
    setAvgModalVisible(false);
  };

  const getGasLimit = async (): Promise<number> => {
    const gasLimit = await axios.get(process.env.REACT_APP_GASLIMIT as string);
    setGasLimit(gasLimit.data.frontendLimit);
    return gasLimit.data.chainlinkLimit;
  };

  const openAvgModal = async (): Promise<void> => {
    setRevealStateLoader(true);
    const gasLimit = await getGasLimit();
    const gasEstimate = await getAverageGasPrice();
    setAvgModalContentStep("STEP1");
    if (gasEstimate <= gasLimit) {
      setAvgModalContentStep("STEP1");
    } else if (gasEstimate > gasLimit) {
      setAvgModalContentStep("STEP3");
    }
    setRevealStateLoader(false);
    setClaimLoaderState(false);
    setAvgModalVisible(true);
  };

  const cancelReveal = (): void => {
    setAvgModalVisible(false);
  };

  const checkPendingReveal = (): void => {
    let storedTokens = localStorage.getItem("txInfo");
    if (storedTokens) navigate("/glass-boxes/reveal");
  };

  const getAverageGasPrice = async (): Promise<number> => {
    const provider = await getProvider();
    const gasPrice = await provider.eth.getGasPrice();
    const gasEstimate = parseInt(provider.utils.fromWei(gasPrice, "Gwei"));
    dispatch({ gasEstimate });
    return gasEstimate;
  };

  useEffect(() => {
    checkPendingReveal();
  }, []);

  useEffect(() => {
    state.user && getTokenIdsAlchemy(state.user);
  }, [state.user]);

  if (loaderState)
    return (
      <div className="randomization-container">
        <Stepper />
        <Loader title={SELECT_TITLE} content={SELECT_CONTENT} />
      </div>
    );

  if (availableTokenIdsState.length === 0) {
    return (
      <div className="randomization-container">
        <div className="randomization-content no-items">
          <NoItems />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="randomization-container">
        <Stepper />
        <div className="randomization-content">
          <div className="randomization-header-container">
            <div className="randomization-header-content">
              <h1>Glass Box Reveal</h1>
              <p>
                You have {availableTokenIdsState.length} DGFamily Glass{" "}
                {availableTokenIdsState.length > 1 ? "Boxes" : "Box"}. Select
                which Boxes you want to reveal. Up to 10 Boxes can be revealed
                at once.
              </p>
            </div>
          </div>
          <div className="reveal-items-container">
            <div className="reveal-items-content">
              {availableTokenIdsState?.map((revealBox: number, i: number) => (
                <RevealItem
                  boxToReveal={revealBox}
                  boxType={3}
                  key={i}
                  size={300}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="reveal-items-actions-container">
          <div className="reveal-items-actions-content">
            <Button
              type="primary"
              size="large"
              onClick={() => openAvgModal()}
              loading={revealStateLoader}
              disabled={state.claimBoxes.length === 0}
            >
              Reveal{" "}
              {state.claimBoxes.length
                ? `${
                    state.claimBoxes.length > 1
                      ? `(${state.claimBoxes.length} Boxes)`
                      : `(${state.claimBoxes.length} Box)`
                  }`
                : ""}
            </Button>
            <span
              className="view-revealed-boxes"
              onClick={() => navigateTo("/revealed-boxes")}
            >
              View Revealed Boxes
            </span>
          </div>
        </div>
      </div>
      <Modal
        className="avg-gas-modal-container"
        centered
        footer={null}
        visible={isAvgModalVisible}
        onCancel={() => handleCancel()}
      >
        {avgGasModalContentStep === "STEP1" ? (
          <div className="avg-gas-modal-content step-one">
            <h1>Attention</h1>
            <p className="important">
              Please carefully read the below and acknowledge if you wish to
              continue:
            </p>
            <ul>
              <li>
                The reveal process will burn your selected Glass Box(es) and
                mint a randomly revealed DGFamily Box(es) to your wallet.{" "}
                <strong>
                  This process can take 2-10 minutes and is irreversible.
                </strong>
              </li>
              <li>
                The Chainlink randomization process is currently limited to a{" "}
                <strong>{gasLimit} gwei cap.</strong>
              </li>
            </ul>
            <p>
              <strong>Do not submit a transaction</strong> if the{" "}
              <a
                href="https://etherscan.io/gastracker"
                target="_blank"
                rel="noreferrer"
              >
                suggested gas costs on Ethereum
              </a>{" "}
              exceed this cap because your transaction could remain pending
              indefinitely until gas prices fall under {gasLimit} gwei.
            </p>
            <div className="avg-gas-modal-actions-container">
              <Button size="large" onClick={() => cancelReveal()}>
                Cancel Reveal
              </Button>
              <Button
                type="primary"
                size="large"
                onClick={() => randomization()}
                loading={claimLoaderState}
              >
                Continue Reveal
              </Button>
            </div>
          </div>
        ) : null}
        {avgGasModalContentStep === "STEP2" ? (
          <div className="avg-gas-modal-content step-two">
            <h1>Attention</h1>
            <p className="important">
              Please read the below before continuing:
            </p>
            <ul>
              <li>
                The reveal process will burn your selected Glass Box(es) and
                mint a randomly revealed DGFamily Box(es) to your wallet. This
                process can take 2-10 minutes and is irreversible.
              </li>
            </ul>
            <div className="avg-gas-modal-actions-container">
              <Button size="large" onClick={() => cancelReveal()}>
                Cancel Reveal
              </Button>
              <Button
                type="primary"
                size="large"
                onClick={() => randomization()}
                loading={claimLoaderState}
              >
                Continue Reveal
              </Button>
            </div>
          </div>
        ) : null}
        {avgGasModalContentStep === "STEP3" ? (
          <div className="avg-gas-modal-content step-three">
            <h1>Attention</h1>
            <p>
              The suggested gas cost on the Ethereum network currently exceeds
              the recommended Chainlink gas lane limit of 60 gwei.
            </p>
            <div className="avg-gas-price-container">
              <span className="title">Avg Gas Price</span>
              <div className="avg-gas-price-value">
                <span className="value">{state.gasEstimate}</span>
                <span className="units">gwei</span>
              </div>
            </div>
            <p>Please return when gas is below 60 gwei.</p>
            <div className="avg-gas-modal-actions-container">
              <Button
                type="primary"
                size="large"
                onClick={() => cancelReveal()}
              >
                Ok
              </Button>
            </div>
          </div>
        ) : null}
      </Modal>
    </>
  );
}

export default Select;
