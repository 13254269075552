import MetaMaskOnboarding from "@metamask/onboarding";

const onboarding = new MetaMaskOnboarding();

export const isMetaMaskInstalled = () =>
  Boolean(window.ethereum && window.ethereum.isMetaMask);

const openedFromMobile = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

const onboardMetaMask = () => {
  if (openedFromMobile()) {
    window.location.href = `https://metamask.app.link/dapp/${process.env.REACT_APP_PUBLIC_URL}/`;
  } else {
    metaMaskOnboarding();
  }
};

const metaMaskOnboarding = () => {
  onboarding.startOnboarding();
};

export const connectWallet = async (dispatch: any) => {
  if (!isMetaMaskInstalled() || openedFromMobile()) {
    onboardMetaMask();
  } else {
    const accounts = await window.ethereum?.request({
      method: "eth_requestAccounts",
    });
    if (accounts?.length) dispatch({ user: accounts[0], connected: true });
  }
};
