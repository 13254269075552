import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { createAlchemyWeb3, Nft } from "@alch/alchemy-web3";

// STATE
import { DgBox, useGlobalState } from "../index";

// ANTD
import { Button } from "antd";

// MODUlES
import Loader from "../shared/Loader";
import NoItems from "../shared/NoItems";
import RevealedItem from "../shared/RevealedItem";

// STYLES
import "./styles.scss";

// CONSTANTS
export const REVEALED_BOXES_TITLE = "Loading";
export const REVEALED_BOXES_CONTENT = "We are loading your DGFamily Boxes ...";

function RevealedBoxes() {
  const [state, dispatch] = useGlobalState();
  const [loaderState, setLoaderState] = useState<boolean>(true);
  const [dgBoxesState, setDgBoxesState] = useState<DgBox[]>([]);

  const navigate = useNavigate();

  const getTokenIdsAlchemy = async (user: string): Promise<void> => {
    const web3 = createAlchemyWeb3(process.env.REACT_APP_ALCHEMY_API as string);
    const nfts = await web3.alchemy.getNfts({
      owner: user,
      contractAddresses: [process.env.REACT_APP_DG_FAMILY_BOX as string],
    });

    const dgBoxes: DgBox[] = nfts.ownedNfts.map((nft: Nft) => {
      return {
        boxType: getBoxTypeByBoxId(parseInt(nft.id.tokenId)),
        dgTokenId: parseInt(nft.id.tokenId),
        frame: getBoxFrameByBoxId(parseInt(nft.id.tokenId)),
      };
    });

    setDgBoxesState(dgBoxes);
    setLoaderState(false);
  };

  const navigateTo = (route: string): void => {
    navigate(route);
  };

  const revealMore = (): void => {
    window.location.href = "/glass-boxes/select";
  };

  const getBoxTypeByBoxId = (dgFamilyBoxId: number): number => {
    if (dgFamilyBoxId <= 75) return 2;
    if (dgFamilyBoxId > 75 && dgFamilyBoxId <= 750) return 1;
    return 0;
  };

  const getBoxFrameByBoxId = (dgFamilyBoxId: number): string => {
    if (dgFamilyBoxId <= 75) return "platinum.jpg";
    if (dgFamilyBoxId > 75 && dgFamilyBoxId <= 750) return "gold.jpg";
    return "black.jpg";
  };

  useEffect(() => {
    state.user && getTokenIdsAlchemy(state.user);
  }, [state.user]);

  if (loaderState)
    return (
      <div className="revelead-boxes-container">
        <Loader title={REVEALED_BOXES_TITLE} content={REVEALED_BOXES_CONTENT} />
      </div>
    );

  if (dgBoxesState.length === 0) {
    return (
      <div className="revelead-boxes-container">
        <div className="revelead-boxes-content no-items">
          <NoItems />
        </div>
      </div>
    );
  }

  return (
    <div className="revelead-boxes-container">
      <div className="revelead-boxes-content">
        <div className="revelead-boxes-header-container">
          <div className="revelead-boxes-header-content">
            <h1>Revealed Boxes</h1>
            <p>
              These are the DGFamily {dgBoxesState.length > 1 ? "Boxes" : "Box"}{" "}
              currently in your wallet.
            </p>
          </div>
        </div>
        <div className="revelead-boxes-items-container">
          <div className="revelead-boxes-items-content">
            {dgBoxesState?.map((dgBox: DgBox, i: number) => (
              <RevealedItem dgBox={dgBox} size={300} key={i} />
            ))}
          </div>
        </div>
      </div>
      <div className="revelead-boxes-items-actions-container">
        <div className="revelead-boxes-items-actions-content">
          <Button type="primary" size="large" onClick={() => revealMore()}>
            Reveal More
          </Button>
          <span
            className="view-glass-boxes"
            onClick={() => navigateTo("/glass-boxes/select")}
          >
            View Glass Boxes
          </span>
        </div>
      </div>
    </div>
  );
}

export default RevealedBoxes;
